var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { on: { click: _vm.drawerClick } },
    [
      _vm.isFormShow
        ? _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                staticStyle: { overflow: "hidden" },
                attrs: {
                  model: _vm.formData.userFile,
                  rules: _vm.validatorRules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-tabs",
                {
                  staticStyle: { "padding-top": "20px" },
                  attrs: { activeKey: _vm.tab },
                  on: { change: _vm.callback },
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "岗位信息" } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          staticClass: "user-photo",
                          attrs: { prop: "avatar", label: "头像" },
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                name: "avatar",
                                "list-type": "picture-card",
                                "show-upload-list": false,
                                "before-upload": _vm.beforeCroUpload,
                              },
                              on: { change: _vm.handleUpChange },
                            },
                            [
                              _vm.formData.userFile.avatar
                                ? _c("div", { staticClass: "img-div" }, [
                                    _c("img", {
                                      ref: "showImg",
                                      staticClass: "upload_img",
                                      attrs: {
                                        src: _vm.formData.userFile.avatar,
                                        alt: "avatar",
                                      },
                                    }),
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("a-icon", {
                                        attrs: {
                                          type: _vm.avatarLoading
                                            ? "loading"
                                            : "plus",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "ant-upload-text" },
                                        [
                                          _vm._v(
                                            "\n                  上传头像\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                          _c("a-input", {
                            attrs: {
                              "allow-clear": "",
                              placeholder: "请粘贴图片或链接",
                            },
                            on: {
                              change: () => {
                                this.textMsg = ""
                              },
                            },
                            nativeOn: {
                              "!paste": function ($event) {
                                $event.preventDefault()
                                return _vm.pasting.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.textMsg,
                              callback: function ($$v) {
                                _vm.textMsg = $$v
                              },
                              expression: "textMsg",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: "聊天工具", prop: "qq" },
                        },
                        [
                          _c(
                            "a-input",
                            {
                              attrs: {
                                placeholder: "请至少填写一种格式正确的账号",
                              },
                              on: { blur: _vm.qqChange },
                              model: {
                                value: _vm.formData.userFile.qq,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData.userFile, "qq", $$v)
                                },
                                expression: "formData.userFile.qq",
                              },
                            },
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  width: "1vw",
                                  position: "relative",
                                  left: "-5px",
                                  "z-index": "999",
                                  color: "hsl(0deg 0% 75%)",
                                },
                                attrs: { slot: "prefix", type: "qq" },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "wechatDiv",
                          staticClass: "qqType-item",
                          attrs: { prop: "qqType" },
                        },
                        [
                          _c("div", {
                            staticStyle: { display: "none" },
                            attrs: { slot: "label" },
                            slot: "label",
                          }),
                          _c(
                            "a-input",
                            {
                              attrs: {
                                placeholder: "请至少填写一种格式正确的账号",
                              },
                              on: { blur: _vm.qqChange },
                              model: {
                                value: _vm.formData.userFile.qqType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData.userFile, "qqType", $$v)
                                },
                                expression: "formData.userFile.qqType",
                              },
                            },
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  width: "1vw",
                                  position: "relative",
                                  left: "-5px",
                                  "z-index": "999",
                                  color: "hsl(0deg 0% 75%)",
                                },
                                attrs: { slot: "prefix", type: "wechat" },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { prop: "contractType", label: "签约类型" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "签约类型" },
                              on: { change: _vm.contractTypeChange },
                              model: {
                                value: _vm.formData.userFile.contractType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.userFile,
                                    "contractType",
                                    $$v
                                  )
                                },
                                expression: "formData.userFile.contractType",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "全职" } },
                                [_vm._v("全职")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "兼职" } },
                                [_vm._v("兼职")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "意向全职" } },
                                [_vm._v("意向全职")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "实习" } },
                                [_vm._v("实习")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { prop: "contractDate", label: "签约日期" } },
                        [
                          _c("a-date-picker", {
                            attrs: { format: "YYYY-MM-DD" },
                            model: {
                              value: _vm.formData.userFile.contractDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData.userFile,
                                  "contractDate",
                                  $$v
                                )
                              },
                              expression: "formData.userFile.contractDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.formData.userFile.contractType ==
                                  "意向全职" ||
                                _vm.formData.userFile.contractType == "兼职",
                              expression:
                                "formData.userFile.contractType == '意向全职' || formData.userFile.contractType == '兼职'",
                            },
                          ],
                          attrs: { label: "是否有全职工作" },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.formData.userFile.workStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.userFile,
                                    "workStatus",
                                    $$v
                                  )
                                },
                                expression: "formData.userFile.workStatus",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("是"),
                              ]),
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v("否"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { prop: "workCity", label: "工作地城市" } },
                        [
                          _c("a-cascader", {
                            attrs: {
                              options: _vm.options,
                              allowClear: "",
                              defaultValue: _vm.formData.userFile.workCity,
                              "change-on-select": "",
                              "show-search": { filter: _vm.filter },
                              placeholder: "请选择工作地城市",
                            },
                            on: { change: _vm.onChange },
                          }),
                        ],
                        1
                      ),
                      _vm.typeShow
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: "擅长作品类型" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-select",
                                  staticStyle: { width: "100%" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        height: "40px",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: _vm.isTag
                                            ? "tag-select-show"
                                            : "tag-select",
                                          on: {
                                            click: function ($event) {
                                              if (
                                                $event.target !==
                                                $event.currentTarget
                                              )
                                                return null
                                              return _vm.isTagShow()
                                            },
                                          },
                                        },
                                        [
                                          _vm.formData.userFile
                                            .goodAtProductionType &&
                                          _vm.formData.userFile
                                            .goodAtProductionType.length > 0 &&
                                          _vm.isShow
                                            ? _vm._l(
                                                _vm.formData.userFile
                                                  .goodAtProductionType,
                                                function (formValue) {
                                                  return _c(
                                                    "a-tag",
                                                    {
                                                      key: formValue,
                                                      attrs: { closable: "" },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.tagClose(
                                                            formValue,
                                                            _vm.formData
                                                              .userFile
                                                              .goodAtProductionType
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(formValue))]
                                                  )
                                                }
                                              )
                                            : _vm.formData.userFile
                                                .goodAtProductionType &&
                                              _vm.formData.userFile
                                                .goodAtProductionType.length >
                                                0 &&
                                              !_vm.isShow
                                            ? _vm._l(
                                                _vm.formData.userFile.goodAtProductionType.split(
                                                  ","
                                                ),
                                                function (formValue) {
                                                  return _c(
                                                    "a-tag",
                                                    {
                                                      key: formValue,
                                                      attrs: { closable: "" },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.tagClose(
                                                            formValue,
                                                            _vm.formData
                                                              .userFile
                                                              .goodAtProductionType
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(formValue))]
                                                  )
                                                }
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "tag-select-open",
                                              style: _vm.isTag
                                                ? ""
                                                : "height:0px;border:none",
                                            },
                                            [
                                              _vm.formData.userFile
                                                .goodAtProductionType
                                                ? _vm._l(
                                                    _vm.typeList,
                                                    function (option) {
                                                      return _c(
                                                        "a-checkable-tag",
                                                        {
                                                          key: option.value,
                                                          ref: option.value,
                                                          refInFor: true,
                                                          style:
                                                            typeof _vm.formData
                                                              .userFile
                                                              .goodAtProductionType !=
                                                            "object"
                                                              ? _vm.formData.userFile.goodAtProductionType.split(
                                                                  ","
                                                                ).length > 5
                                                                ? "pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;"
                                                                : ""
                                                              : _vm.formData
                                                                  .userFile
                                                                  .goodAtProductionType
                                                                  .length > 5
                                                              ? "pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;"
                                                              : "",
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.tagChange(
                                                                option.checked,
                                                                option.value
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              option.checked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                option,
                                                                "checked",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "option.checked",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(option.value)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                : _vm._l(
                                                    _vm.typeList,
                                                    function (option) {
                                                      return _c(
                                                        "a-checkable-tag",
                                                        {
                                                          key: option.value,
                                                          ref: option.value,
                                                          refInFor: true,
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.tagChange(
                                                                option.checked,
                                                                option.value
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              option.checked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                option,
                                                                "checked",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "option.checked",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(option.value)
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "ant-select-arrow" },
                                            [
                                              _c("a-icon", {
                                                staticStyle: {
                                                  color: "rgba(0, 0, 0, 0.25)",
                                                },
                                                attrs: {
                                                  type: _vm.isTag
                                                    ? "up"
                                                    : "down",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.scShow
                                ? _c("a-textarea", {
                                    attrs: {
                                      autoSize: "",
                                      placeholder: "请输入擅长的作品类型",
                                    },
                                    model: {
                                      value:
                                        _vm.formData.userFile
                                          .skilledProductOther,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "skilledProductOther",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.userFile.skilledProductOther",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "抵触作品类型" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-select",
                              staticStyle: { width: "100%", height: "120px" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "40px",
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: _vm.isTagTwo
                                        ? "tag-select-show"
                                        : "tag-select",
                                      on: {
                                        click: function ($event) {
                                          if (
                                            $event.target !==
                                            $event.currentTarget
                                          )
                                            return null
                                          return _vm.isTagShowTwo()
                                        },
                                      },
                                    },
                                    [
                                      _vm.formData.userFile
                                        .badAtProductionType &&
                                      _vm.formData.userFile.badAtProductionType
                                        .length > 0 &&
                                      _vm.isShow
                                        ? _vm._l(
                                            _vm.formData.userFile
                                              .badAtProductionType,
                                            function (formValue) {
                                              return _c(
                                                "a-tag",
                                                {
                                                  key: formValue,
                                                  attrs: { closable: "" },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.tagCloseTwo(
                                                        formValue,
                                                        _vm.formData.userFile
                                                          .badAtProductionType
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(formValue))]
                                              )
                                            }
                                          )
                                        : _vm.formData.userFile
                                            .badAtProductionType &&
                                          _vm.formData.userFile
                                            .badAtProductionType.length > 0 &&
                                          !_vm.isShow
                                        ? _vm._l(
                                            _vm.formData.userFile.badAtProductionType.split(
                                              ","
                                            ),
                                            function (formValue) {
                                              return _c(
                                                "a-tag",
                                                {
                                                  key: formValue,
                                                  attrs: { closable: "" },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.tagCloseTwo(
                                                        formValue,
                                                        _vm.formData.userFile
                                                          .badAtProductionType
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(formValue))]
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tag-select-open",
                                          style: _vm.isTagTwo
                                            ? ""
                                            : "height:0px;border:none",
                                        },
                                        [
                                          _vm.formData.userFile
                                            .badAtProductionType
                                            ? _vm._l(
                                                _vm.typeListTwo,
                                                function (option) {
                                                  return _c(
                                                    "a-checkable-tag",
                                                    {
                                                      key: option.value,
                                                      ref: option.value,
                                                      refInFor: true,
                                                      style:
                                                        typeof _vm.formData
                                                          .userFile
                                                          .badAtProductionType !=
                                                        "object"
                                                          ? _vm.formData.userFile.badAtProductionType.split(
                                                              ","
                                                            ).length > 5
                                                            ? "pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;"
                                                            : ""
                                                          : _vm.formData
                                                              .userFile
                                                              .badAtProductionType
                                                              .length > 5
                                                          ? "pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;"
                                                          : "",
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.tagChangeTwo(
                                                            option.checked,
                                                            option.value
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: option.checked,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            option,
                                                            "checked",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "option.checked",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.value)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              )
                                            : _vm._l(
                                                _vm.typeListTwo,
                                                function (option) {
                                                  return _c(
                                                    "a-checkable-tag",
                                                    {
                                                      key: option.value,
                                                      ref: option.value,
                                                      refInFor: true,
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.tagChangeTwo(
                                                            option.checked,
                                                            option.value
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: option.checked,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            option,
                                                            "checked",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "option.checked",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.value)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "ant-select-arrow" },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              color: "rgba(0, 0, 0, 0.25)",
                                            },
                                            attrs: {
                                              type: _vm.isTagTwo
                                                ? "up"
                                                : "down",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm.dcShow
                                ? _c("a-textarea", {
                                    attrs: {
                                      autoSize: "",
                                      placeholder: "请输入抵触的作品类型",
                                    },
                                    model: {
                                      value:
                                        _vm.formData.userFile
                                          .unskilledProductOther,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData.userFile,
                                          "unskilledProductOther",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData.userFile.unskilledProductOther",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "2",
                      attrs: { tab: "个人档案", "force-render": "" },
                    },
                    [
                      _vm.tab == "2"
                        ? _c(
                            "span",
                            {
                              staticStyle: {
                                position: "absolute",
                                "font-size": "12px",
                                top: "100px",
                                color: "#aca9a9",
                              },
                            },
                            [_vm._v("下列信息仅BOSS及HR有权查看，请如实填写。")]
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "姓名" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入姓名" },
                            model: {
                              value: _vm.formData.userFile.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.userFile, "userName", $$v)
                              },
                              expression: "formData.userFile.userName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "性别" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.formData.userFile.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData.userFile, "sex", $$v)
                                },
                                expression: "formData.userFile.sex",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v("男"),
                              ]),
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v("女"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "出生日期" } },
                        [
                          _c("a-date-picker", {
                            attrs: { format: "YYYY-MM-DD" },
                            model: {
                              value: _vm.formData.userFile.bornDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.userFile, "bornDate", $$v)
                              },
                              expression: "formData.userFile.bornDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "国籍" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "国籍" },
                              model: {
                                value: _vm.formData.userFile.country,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData.userFile,
                                    "country",
                                    $$v
                                  )
                                },
                                expression: "formData.userFile.country",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "中国" } },
                                [_vm._v("中国")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "美国" } },
                                [_vm._v("美国")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "英国" } },
                                [_vm._v("英国")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "日本" } },
                                [_vm._v("日本")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "韩国" } },
                                [_vm._v("韩国")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "电话号码" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入电话号码" },
                            model: {
                              value: _vm.formData.userFile.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData.userFile, "phone", $$v)
                              },
                              expression: "formData.userFile.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      (_vm.country == "qt" || _vm.country == "gat") &&
                      _vm.formData.userFile.contractType != "意向全职" &&
                      _vm.formData.userFile.contractType != "兼职"
                        ? [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "padding-top": "10px",
                                  "padding-bottom": "15px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "160px",
                                      "font-weight": "bold",
                                      "font-size": "16px",
                                    },
                                  },
                                  [_vm._v("英文银行账户信息")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "rgba(0,0,0,0.25)",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "以下内容除收款账号，其余内容请输入英文信息"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "户名" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入户名" },
                                  model: {
                                    value: _vm.formData.userFile.paymentName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "paymentName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.paymentName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "收款账号" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入收款账号" },
                                  model: {
                                    value: _vm.formData.userFile.bankAccount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "bankAccount",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.bankAccount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "银行名称" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入银行名称" },
                                  model: {
                                    value: _vm.formData.userFile.bankName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "bankName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.bankName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "支行名称" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入支行名称" },
                                  model: {
                                    value: _vm.formData.userFile.branchBank,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "branchBank",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.branchBank",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "SWIFT CODE" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入SWIFT CODE" },
                                  model: {
                                    value: _vm.formData.userFile.paymentBank,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "paymentBank",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.paymentBank",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "银行地址" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入银行地址" },
                                  model: {
                                    value: _vm.formData.userFile.bankAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "bankAddress",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.bankAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "个人地址" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入个人地址" },
                                  model: {
                                    value:
                                      _vm.formData.userFile.personalAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "personalAddress",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.userFile.personalAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : [
                            _vm.alipayAccountShow
                              ? _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "收款账号",
                                      prop:
                                        _vm.country != "qt" &&
                                        _vm.country != "gat" &&
                                        _vm.formData.userFile.contractType !=
                                          "意向全职" &&
                                        _vm.formData.userFile.contractType !=
                                          "兼职" &&
                                        _vm.formData.userFile.alipayAccount
                                          ? "alipayAccount"
                                          : "",
                                    },
                                  },
                                  [
                                    _vm.formData.userFile.contractType ==
                                      "意向全职" ||
                                    _vm.formData.userFile.contractType == "兼职"
                                      ? _c(
                                          "a-select",
                                          {
                                            attrs: {
                                              placeholder: "请选择收款方式",
                                              "default-value":
                                                _vm.formData.userFile
                                                  .paymentType,
                                            },
                                            on: {
                                              change: _vm.paymentTypeChange,
                                            },
                                          },
                                          [
                                            _c(
                                              "a-select-option",
                                              {
                                                attrs: { value: "EN_ACCOUNT" },
                                              },
                                              [
                                                _c("a-icon", {
                                                  staticStyle: {
                                                    width: "1vw",
                                                    position: "relative",
                                                    left: "-3px",
                                                  },
                                                  attrs: { type: "bank" },
                                                }),
                                                _vm._v(
                                                  "\n                  全英账户"
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "ALIPAY" } },
                                              [
                                                _c("a-icon", {
                                                  staticStyle: {
                                                    width: "1vw",
                                                    position: "relative",
                                                    left: "-3px",
                                                  },
                                                  attrs: {
                                                    type: "alipay-circle",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                  支付宝"
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "WECHAT" } },
                                              [
                                                _c("a-icon", {
                                                  staticStyle: {
                                                    width: "1vw",
                                                    position: "relative",
                                                    left: "-3px",
                                                  },
                                                  attrs: { type: "wechat" },
                                                }),
                                                _vm._v(
                                                  "\n                  微信"
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.formData.userFile &&
                                            _vm.formData.userFile.workCity &&
                                            typeof _vm.formData.userFile
                                              .workCity === "object" &&
                                            _vm.formData.userFile.workCity.some(
                                              (item) => item == 83
                                            )
                                              ? _c(
                                                  "a-select-option",
                                                  { attrs: { value: "BANK" } },
                                                  [
                                                    _c("a-icon", {
                                                      staticStyle: {
                                                        width: "1vw",
                                                        position: "relative",
                                                        left: "-3px",
                                                      },
                                                      attrs: {
                                                        type: "credit-card",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                  银行账户"
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.country != "qt" &&
                                    _vm.country != "gat" &&
                                    _vm.formData.userFile.contractType !=
                                      "意向全职" &&
                                    _vm.formData.userFile.contractType != "兼职"
                                      ? _c(
                                          "a-input",
                                          {
                                            attrs: {
                                              placeholder: "请输入招行卡号",
                                            },
                                            model: {
                                              value:
                                                _vm.formData.userFile
                                                  .alipayAccount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData.userFile,
                                                  "alipayAccount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formData.userFile.alipayAccount",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "1vw",
                                                position: "relative",
                                                left: "-4px",
                                              },
                                              attrs: {
                                                slot: "prefix",
                                                src: require("./../../assets/zh.png"),
                                                alt: "",
                                              },
                                              slot: "prefix",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.formData.userFile.paymentType ==
                                    "ALIPAY"
                                      ? _c("a-input", {
                                          attrs: {
                                            placeholder: "请输入支付宝账号",
                                          },
                                          model: {
                                            value:
                                              _vm.formData.userFile
                                                .alipayAccount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData.userFile,
                                                "alipayAccount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.userFile.alipayAccount",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.formData.userFile.paymentType ==
                                    "WECHAT"
                                      ? _c("a-input", {
                                          attrs: {
                                            placeholder: "请输入微信账号",
                                          },
                                          model: {
                                            value:
                                              _vm.formData.userFile
                                                .alipayAccount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData.userFile,
                                                "alipayAccount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.userFile.alipayAccount",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.formData.userFile.paymentType ==
                                      "BANK" &&
                                    _vm.formData.userFile.contractType !=
                                      "全职" &&
                                    _vm.formData.userFile.contractType != "实习"
                                      ? [
                                          _vm.country != "qt" &&
                                          _vm.country != "gat"
                                            ? _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    "show-search": "",
                                                    placeholder: "请选择开户行",
                                                    "option-filter-prop":
                                                      "label",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData.userFile
                                                        .bankName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formData.userFile,
                                                        "bankName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData.userFile.bankName",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.bankList,
                                                  function (item) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.text,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.text)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.country != "qt" &&
                                          _vm.country != "gat"
                                            ? _c("a-input", {
                                                attrs: {
                                                  placeholder: "请输入银行卡号",
                                                },
                                                on: { blur: _vm.submitBank },
                                                model: {
                                                  value:
                                                    _vm.formData.userFile
                                                      .bankAccount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData.userFile,
                                                      "bankAccount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.userFile.bankAccount",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.country != "qt" &&
                                          _vm.country != "gat"
                                            ? _c("a-input", {
                                                attrs: {
                                                  placeholder: "请输入户名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.formData.userFile
                                                      .paymentName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.formData.userFile,
                                                      "paymentName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formData.userFile.paymentName",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                      _vm.formData.userFile.paymentType == "BANK" &&
                      _vm.formData.userFile.contractType != "全职" &&
                      _vm.formData.userFile.contractType != "实习" &&
                      _vm.country == "qt"
                        ? [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "名前片假名" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入名前片假名" },
                                  model: {
                                    value: _vm.formData.userFile.paymentName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "paymentName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.paymentName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "銀行名" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入銀行名" },
                                  model: {
                                    value: _vm.formData.userFile.bankName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "bankName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.bankName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "店番号" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入店番号" },
                                  model: {
                                    value: _vm.formData.userFile.bankAccount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "bankAccount",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.bankAccount",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "支店名" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入支店名" },
                                  model: {
                                    value: _vm.formData.userFile.branchBank,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "branchBank",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.branchBank",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "口座番号" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入口座番号" },
                                  model: {
                                    value: _vm.formData.userFile.bankAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "bankAddress",
                                        $$v
                                      )
                                    },
                                    expression: "formData.userFile.bankAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "預金種別" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入預金種別" },
                                  model: {
                                    value:
                                      _vm.formData.userFile.personalAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData.userFile,
                                        "personalAddress",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.userFile.personalAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "12px",
                            position: "relative",
                            top: "-3px",
                          },
                        },
                        [
                          _vm._v(
                            "*本人承诺以上所填信息真实有效，若有虚假，本人自愿承担带来的经济损失"
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }